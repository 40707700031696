var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-call"},[_c('button',{staticClass:"px-3 btn btn-danger btn-sm",staticStyle:{"margin-bottom":"10px"},on:{"click":function($event){return _vm.onCancel()}}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.calls.excel.single-excel.cancel-process-file'))+" ")]),_c('button',{staticClass:"px-3 btn btn-sm btn-warning",staticStyle:{"margin-bottom":"10px","margin-left":"10px"},on:{"click":function($event){return _vm.ignoreAll()}}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.calls.excel.single-excel.ignore-all-corrupted'))+" ")]),_c('data-table',{ref:"corruptedCallsTable",attrs:{"data":_vm.corruptedRows,"fields":_vm.translatedTableFields,"pagination":_vm.pagination,"loading":_vm.corruptedRowLoading},on:{"changePage":function($event){return _vm.onPageChange($event)}},scopedSlots:_vm._u([{key:"reasons",fn:function({ row }){return _vm._l((row.reasons),function(reason){return _c('b-badge',{key:reason,staticClass:"m-2",attrs:{"variant":"warning"}},[_vm._v(" "+_vm._s(reason)+" ")])})}},{key:"actions",fn:function({ row }){return [_c('button',{staticClass:"px-3 btn btn-primary btn-sm",on:{"click":function($event){return _vm.toggleDetails(row)}}},[_c('i',{class:_vm.$refs.corruptedCallsTable.activeRowDetailsId === row.id
              ? 'fa fa-eye-slash'
              : 'fa fa-eye'})])]}},{key:"details",fn:function(){return [_c('field',{staticClass:"mb-2 w-100",attrs:{"label":_vm.$t(
            'call-campaign.calls.excel.single-excel.table.details.form.phone.label'
          ),"placeholder":_vm.$t(
            'call-campaign.calls.excel.single-excel.table.details.form.phone.placeholder'
          ),"error":_vm.fixCorruptedErrors.phone},model:{value:(_vm.corruptedRowModel.phone),callback:function ($$v) {_vm.$set(_vm.corruptedRowModel, "phone", $$v)},expression:"corruptedRowModel.phone"}}),_c('div',{staticClass:"w-100 params-container"},[_c('div',{staticClass:"params"},[_c('h5',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.calls.excel.single-excel.table.details.form.params' ))+" ")]),_vm._l((_vm.params),function(param,idx){return _c('div',{key:param.variable_name,staticClass:"mb-2 param"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(param.variable_name)+" ("+_vm._s(param.type)+")")]),(param.type === 'money')?_c('div',{staticClass:"flex-fill money-inputs"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('b-form-input',{staticClass:"mx-2 pl-2 border",attrs:{"placeholder":_vm.$t(
                          'call-campaign.calls.excel.single-excel.table.details.form.amount.placeholder'
                        ),"value":_vm.corruptedRowModel.variables[idx].value.amount},on:{"change":function($event){_vm.corruptedRowModel.variables[
                          idx
                        ].value.amount = +$event}}})],1),_c('div',{staticClass:"col-6"},[_c('b-form-select',{staticClass:"border",attrs:{"options":_vm.currencies},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":undefined,"disabled":""}},[_vm._v(" "+_vm._s(_vm.$t( 'call-campaign.calls.excel.single-excel.table.details.form.currency.placeholder' ))+" ")])]},proxy:true}],null,true),model:{value:(
                        _vm.corruptedRowModel.variables[idx].value.currency
                      ),callback:function ($$v) {_vm.$set(_vm.corruptedRowModel.variables[idx].value, "currency", $$v)},expression:"\n                        corruptedRowModel.variables[idx].value.currency\n                      "}})],1)])]):_vm._e(),(param.type === 'full-number')?_c('div',{staticClass:"flex-fill full-number-inputs"},[_c('b-form-input',{staticClass:"mx-2 pl-2 border",attrs:{"placeholder":_vm.$t(
                      'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                    )},model:{value:(_vm.corruptedRowModel.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.corruptedRowModel.variables[idx], "value", $$v)},expression:"corruptedRowModel.variables[idx].value"}})],1):_vm._e(),(param.type === 'separated-number')?_c('div',{staticClass:"flex-fill separated-number-inputs"},[_c('b-form-input',{staticClass:"mx-2 pl-2 border",attrs:{"placeholder":_vm.$t(
                      'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                    )},model:{value:(_vm.corruptedRowModel.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.corruptedRowModel.variables[idx], "value", $$v)},expression:"corruptedRowModel.variables[idx].value"}})],1):_vm._e(),(param.type === 'text')?_c('div',{staticClass:"flex-fill text-inputs"},[_c('b-form-textarea',{staticClass:"mx-2 pl-2 border",attrs:{"placeholder":_vm.$t(
                      'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                    ),"rows":"3","max-rows":"6"},model:{value:(_vm.corruptedRowModel.variables[idx].value),callback:function ($$v) {_vm.$set(_vm.corruptedRowModel.variables[idx], "value", $$v)},expression:"corruptedRowModel.variables[idx].value"}})],1):_vm._e()])])})],2)]),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.fixCorruptedRow()}}},[_vm._v(" "+_vm._s(_vm.$t('call-campaign.calls.excel.single-excel.table.details.form.fix'))+" ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }