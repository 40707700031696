<template>
  <div class="file-call">
    <button
      class="px-3 btn btn-danger btn-sm"
      style="margin-bottom: 10px;"
      @click="onCancel()"
    >
      {{ $t('call-campaign.calls.excel.single-excel.cancel-process-file') }}
    </button>

    <button
      class="px-3 btn btn-sm btn-warning"
      style="margin-bottom: 10px; margin-left: 10px"
      @click="ignoreAll()"
    >
      {{ $t('call-campaign.calls.excel.single-excel.ignore-all-corrupted') }}
    </button>

    <data-table
      ref="corruptedCallsTable"
      :data="corruptedRows"
      :fields="translatedTableFields"
      :pagination="pagination"
      @changePage="onPageChange($event)"
      :loading="corruptedRowLoading"
    >
      <template v-slot:reasons="{ row }">
        <b-badge
          v-for="reason in row.reasons"
          :key="reason"
          variant="warning"
          class="m-2"
        >
          {{ reason }}
        </b-badge>
      </template>
      <template v-slot:actions="{ row }">
        <button class="px-3 btn btn-primary btn-sm" @click="toggleDetails(row)">
          <i
            :class="
              $refs.corruptedCallsTable.activeRowDetailsId === row.id
                ? 'fa fa-eye-slash'
                : 'fa fa-eye'
            "
          ></i>
        </button>
      </template>
      <template v-slot:details>
        <field
          class="mb-2 w-100"
          v-model="corruptedRowModel.phone"
          :label="
            $t(
              'call-campaign.calls.excel.single-excel.table.details.form.phone.label'
            )
          "
          :placeholder="
            $t(
              'call-campaign.calls.excel.single-excel.table.details.form.phone.placeholder'
            )
          "
          :error="fixCorruptedErrors.phone"
        >
        </field>

        <div class="w-100 params-container">
          <div class="params">
            <h5 class="mt-0">
              {{
                $t(
                  'call-campaign.calls.excel.single-excel.table.details.form.params'
                )
              }}
            </h5>
            <div
              v-for="(param, idx) in params"
              class="mb-2 param"
              :key="param.variable_name"
            >
              <div class="d-flex align-items-center">
                <p class="m-0">{{ param.variable_name }} ({{ param.type }})</p>
                <div
                  class="flex-fill money-inputs"
                  v-if="param.type === 'money'"
                >
                  <div class="row">
                    <div class="col-6">
                      <b-form-input
                        class="mx-2 pl-2 border"
                        :placeholder="
                          $t(
                            'call-campaign.calls.excel.single-excel.table.details.form.amount.placeholder'
                          )
                        "
                        :value="corruptedRowModel.variables[idx].value.amount"
                        @change="
                          corruptedRowModel.variables[
                            idx
                          ].value.amount = +$event
                        "
                      ></b-form-input>
                    </div>
                    <div class="col-6">
                      <b-form-select
                        v-model="
                          corruptedRowModel.variables[idx].value.currency
                        "
                        class="border"
                        :options="currencies"
                      >
                        <template #first>
                          <b-form-select-option :value="undefined" disabled>
                            {{
                              $t(
                                'call-campaign.calls.excel.single-excel.table.details.form.currency.placeholder'
                              )
                            }}
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                </div>
                <div
                  class="flex-fill full-number-inputs"
                  v-if="param.type === 'full-number'"
                >
                  <b-form-input
                    class="mx-2 pl-2 border"
                    :placeholder="
                      $t(
                        'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                      )
                    "
                    v-model="corruptedRowModel.variables[idx].value"
                  ></b-form-input>
                </div>
                <div
                  class="flex-fill separated-number-inputs"
                  v-if="param.type === 'separated-number'"
                >
                  <b-form-input
                    class="mx-2 pl-2 border"
                    :placeholder="
                      $t(
                        'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                      )
                    "
                    v-model="corruptedRowModel.variables[idx].value"
                  ></b-form-input>
                </div>
                <div class="flex-fill text-inputs" v-if="param.type === 'text'">
                  <b-form-textarea
                    class="mx-2 pl-2 border"
                    :placeholder="
                      $t(
                        'call-campaign.calls.excel.single-excel.table.details.form.value.placeholder'
                      )
                    "
                    v-model="corruptedRowModel.variables[idx].value"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary btn-sm" @click="fixCorruptedRow()">
          {{
            $t('call-campaign.calls.excel.single-excel.table.details.form.fix')
          }}
        </button>
      </template>
    </data-table>
  </div>
</template>

<script>
import callCampaignService from '../../services/callCampaign.service'
import DataTable from '../../elements/Table.vue'
import { currencies } from '../../data/Currencies'
import { object, string } from 'yup'
import { yupToKV } from '../../utils/yup'
import Field from '../../elements/Field.vue'
import { isValidPhoneNumber } from '../../utils/phone'

export default {
  components: { DataTable, Field },
  data() {
    return {
      fields: [
        {
          accessor: 'status',
          header: 'call-campaign.calls.excel.single-excel.table.status'
        },
        {
          accessor: 'reasons',
          header: 'call-campaign.calls.excel.single-excel.table.reasons'
        },
        {
          accessor: 'actions',
          header: 'call-campaign.calls.excel.single-excel.table.actions'
        }
      ],
      corruptedRows: [],
      corruptedRowLoading: false,
      pagination: {
        totalPages: 0,
        page: 1
      },
      corruptedRowModel: {
        phone: '',
        variables: []
      },
      fixCorruptedErrors: {},
      currencies: currencies
    }
  },
  mounted() {
    this.getCorruptedRows()
  },
  methods: {
    onPageChange(page) {
      this.pagination.page = page
      this.getCorruptedRows(page)
    },
    removeDuplicateParams(arr) {
      const uniqueArray = arr.filter((value, index) => {
        return (
          index ===
          arr.findIndex(obj => {
            return obj.variable_name === value.variable_name
          })
        )
      })

      return uniqueArray
    },
    async getCorruptedRows(page = 1) {
      this.corruptedRowLoading = true
      const result = await callCampaignService.getCorruptedRows(
        this.$route.params.id,
        page
      )
      const { corruptedRows, scenarioId, ...pagination } = result
      this.corruptedRows = corruptedRows

      const paramsResult = await callCampaignService.getCallScenarioParams(
        scenarioId
      )
      // remove duplicates
      const ignoredParams = ['orderNumber']
      const uniqueParams = this.removeDuplicateParams(paramsResult)
      this.params = uniqueParams.filter(
        param => !ignoredParams.includes(param.variable_name)
      )

      const defaultValues = {
        money: {},
        text: '',
        'separated-number': '',
        'full-number': ''
      }

      this.corruptedRowModel = {
        ...this.corruptedRowModel,
        variables: uniqueParams
          .filter(param => !ignoredParams.includes(param.variable_name))
          .map(param => ({
            variable_name: param.variable_name,
            value: JSON.parse(JSON.stringify(defaultValues[param.type]))
          }))
      }

      this.corruptedRows = corruptedRows.map(row => ({
        ...row,
        corruptedKeys: Object.keys(row.row),
        corruptions: row.row
      }))

      this.corruptedRowLoading = false
      this.pagination.totalPages = pagination.totalPages
    },
    async onIgnore(id) {
      await callCampaignService.ignoreCorruptedRow(id)
      this.getCorruptedRows()
    },
    toggleDetails(row) {
      const variableValue = param => {
        if (!!row.row) {
          if (param.type === 'money') {
            const [amount, currency] = row.row[param.variable_name].split(':')
            return {
              amount,
              currency
            }
          }
          return row.row[param.variable_name]
        }
        return ''
      }

      this.corruptedRowModel = {
        ...this.corruptedRowModel,
        id: row.id,
        phone: row.row.phone,
        orderNumber: row.row.orderNumber,
        variables: this.params.map(param => ({
          variable_name: param.variable_name,
          value: variableValue(param)
        }))
      }

      this.$refs.corruptedCallsTable.toggleDetails(row.id)
    },
    async onCancel() {
      try {
        await callCampaignService.cancelCallFile(this.$route.params.id)
        this.$router.push({ name: 'call-campaign-excel-calls-list' })
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    async ignoreAll() {
      try {
        await callCampaignService.ignoreAllCorrupted(this.$route.params.id)
        this.$router.push({ name: 'call-campaign-excel-calls-list' })
      } catch (error) {
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    },
    async fixCorruptedRow() {
      try {
        this.corruptedRowModel = {
          ...this.corruptedRowModel,
          phone: this.corruptedRowModel.phone.replace(/\s/g, '')
        }

        await this.fixCorruptedSchema
          .validate(this.corruptedRowModel, { abortEarly: false })
          .catch(err => {
            this.fixCorruptedErrors = yupToKV(err)
          })

        if (!this.fixCorruptedSchema.isValidSync(this.corruptedRowModel)) {
          return
        }

        this.fixCorruptedErrors = {}
        await callCampaignService.fixCorruptedRow(
          this.corruptedRowModel.id,
          this.corruptedRowModel
        )
        this.getCorruptedRows(this.pagination.page)
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, {
          type: 'error'
        })
      }
    }
  },
  computed: {
    fixCorruptedSchema() {
      return object().shape({
        phone: string()
          .trim()
          .test(
            'isValidPhone',
            this.$t(
              'call-campaign.calls.excel.single-excel.table.details.errors.invalid.phone'
            ),
            isValidPhoneNumber
          )
          .required(
            this.$t(
              'call-campaign.calls.excel.single-excel.table.details.errors.required.phone'
            )
          )
      })
    },
    translatedTableFields() {
      return this.fields.map(field => {
        return {
          ...field,
          header: this.$t(field.header)
        }
      })
    }
  }
}
</script>

<style></style>
